@layer tailwind {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

@property --loader-bg {
  syntax: '<color>';
  inherits: true;
  initial-value: transparent;
}

html {
  height: 100%;
}

body {
  color: var(--mantine-color-dark-9);
  background: var(--mantine-color-gray-0);
  min-height: 100%;
}

.input-theme:focus {
  border: 1px solid var(--mantine-color-gray-6);
}

.checkbox-theme {
  border: 1px solid transparent;
}

.loader-theme::after {
  border-color: var(--loader-color) var(--loader-color) var(--loader-color) var(--loader-bg);
}

.PhoneInputInput:focus {
  outline: none;
}

.cm__texts,
.cm__desc {
  min-height: fit-content !important;
}
